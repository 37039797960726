// TODO
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const TileList = ({title, body, list, animation = {}}) => {
    let tileClass = 'tile tile--list'
    return (
        <div className={tileClass} {...animation}>
            <div className="tile__header">
                {body ? (
                    <>
                        <h3>{title}</h3>
                        <div className="tile__header__subtitle">
                            <ReactMarkdown children={body} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </>
                    ) : (
                    <>
                        <h4>{title}</h4>   
                    </>
                )}
            </div>
            <div className="tile__body">
                <ReactMarkdown children={list} rehypePlugins={[rehypeRaw]} />
            </div>
        </div>
    )
}

export default TileList