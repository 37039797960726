import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import Container from "../components/Layout/container"
import MainSection100 from "../components/Layout/main-section-100"
import MainSection50 from "../components/Layout/main-section-50"
import NewLayout from "../components/new_layout"
import Seo from "../components/seo"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import ItemGroup from "../components/v2023/UI/Item/item-group"
import Avatar from "../components/v2024/avatar/avatar"
import GroupImageGroup from "../components/v2024/image/group-image-group"
import ImageGroup from "../components/v2024/image/image-group"
import InfiniteImageCarousel from "../components/v2024/image/infinite-image-carousel"
import TileList from "../components/v2024/tile/tile-list"
import { ModalForm, ModalState } from "../components/v2024/modal"

const PartnersPage = ({ data, pageContext }) => {
    const page = data.page
    const lang = pageContext.langKey

    const menuState = ModalState()

    page.icons_who_partners = page.icons_who_partners.map(who_partner => {
        who_partner.title = null
        return who_partner
    })

    return (
        <NewLayout
            pageContext={pageContext}
            lang={pageContext?.langKey}
            translates={pageContext?.translates}
        >
            <Seo
                lang={lang}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo.image_2.localFile.publicURL}
            />
            <main className="main">
                <ModalForm state={menuState} form={page.modal_form} />
                {/* CTA */}
                <MainSection50 sectionColor="green" sectionType="color" sectionSize="lg" childrenLeft={
                    <>
                        <h1 className="merriweather">{page.cta_heading.name}</h1>
                        <div className="container__text">
                            <ReactMarkdown children={page.cta_heading.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <button onClick={menuState.openMenu} className="button button--xl">
                            {page.cta_heading.button}
                        </button>
                    </>
                } childrenRight={
                    <div data-aos="fade-left">
                        <GatsbyImage alt={page.cta_heading.image.alternativeText} image={getImage(page.cta_heading.image.localFile)} />
                    </div>
                }/>

                {/* Descripción Partners */}
                <MainSection100>
                    <div className="container__text">
                        <h2>{page.title_who_partners}</h2>
                        <ReactMarkdown
                            children={page.desc_who_partners}
                            rehypePlugins={[rehypeRaw]}
                        />
                    </div>
                    <GroupImageGroup itemGroup={page.icons_who_partners} maxShownDesktop="8" maxShownMobile="8" offsetMobile="4" buttonText={page.button_who_partners}  gridItems="4" small/>
                </MainSection100>

                {/* ¿Por qué Tecalis? */}
                <MainSection50 sectionColor="gray" sectionType="color" sectionSize="md" childrenRight={
                    <>
                        <h2>{page.cta_why_tecalis.name}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.cta_why_tecalis.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <Link
                            to="#ready-to-talk"
                            className="button button--xl"
                        >
                            {page.cta_why_tecalis.button}
                        </Link>
                    </>
                } childrenLeft={
                    <div data-aos="fade-right">
                        <GatsbyImage alt={page.cta_why_tecalis.image.alternativeText} image={getImage(page.cta_why_tecalis.image.localFile)} />
                    </div>
                } />

                {/* Ventajas */}
                <MainSection100>
                    <GroupImageGroup itemGroup={page.advantages} maxShownDesktop="4" maxShownMobile="4" gridItems="4"/>
                </MainSection100>

                {/* Dudas */}
                <MainSection100>
                    <h2>{page.title_doubts}</h2>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={page.desc_doubts} className="container__text" />
                    <Avatar
                        avatar={page.contact_doubts}
                        vertical
                    />
                </MainSection100>

                {/* Programa de partners */}
                <MainSection100 sectionColor="purple-dark" sectionType="color">
                    <h2>{page.know_program.title}</h2>
                    <div className="grid-lg-4">
                        {page.know_program.items.map((item, index) => {
                            const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                            return (
                                <TileList key={index} title={item.title} body={item.description} list={item.explanation} animation={animation} />
                            )
                        })}
                    </div>
                    <a className="button button--ghost button--xl" href="#ready-to-talk">{page.know_program.button}</a>
                </MainSection100>

                {/* Integraciones */}
                <MainSection100 noContainer>
                    <Container>
                        <h2>{page.title_integrations}</h2>
                        <div className="container__text">
                            <ReactMarkdown
                                children={page.desc_integrations}
                                rehypePlugins={rehypeRaw}
                            />
                        </div>
                    </Container>
                    <InfiniteImageCarousel images={page.logos_integrations} />
                    <Container>
                        <div className="grid-lg-3-h">
                            {page.explanations_integrations.map((explanation, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return (
                                    <ImageGroup key={index} basicInfoWithImage={explanation} animation={animation} />
                                )
                            })}
                        </div>
                    </Container>
                </MainSection100>

                {/* ¿Hablamos? */}
                <ReadyToTalk lang={lang} hideTags title={page.modal_form.title} description={page.modal_form.description} form={page.modal_form} />

                {/* FaQ */}
                <MainSection100 sectionId="test" containerSlim>
                    <h2>{page.title_faq}</h2>
                    <ItemGroup items={page.item_faq} unboxed />
                </MainSection100>
            </main>
        </NewLayout>
    )
}

export default PartnersPage

export const partnersQuery = graphql`
    query ($langKey: String) {
        page: strapi2024Partners(locale: { eq: $langKey }) {
            seo {
                title
                meta_description
                image_2 {
                  localFile {
                      publicURL
                  }
                }
            }
            cta_heading {
                name
                description
                button
                url
                blank
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 656
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [608]
                            )
                        }
                    }
                }
            }
            title_who_partners
            desc_who_partners
            icons_who_partners {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 64
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                title
            }
            button_who_partners
            cta_why_tecalis {
                name
                description
                button
                url
                blank
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [512]
                            )
                        }
                    }
                }
            }
            advantages {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 444
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [314]
                            )
                        }
                    }
                }
            }
            title_doubts
            desc_doubts
            contact_doubts {
                name
                department
                linkedin
                mail
                phone_prefix
                phone
                whatsapp_prefix
                whatsapp
                avatar {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            know_program {
                title
                items {
                    title
                    description
                    explanation
                }
                button
            }
            title_integrations
            desc_integrations
            logos_integrations {
                id
                alt
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 64
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            explanations_integrations {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                quality: 100
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            title_faq
            item_faq {
                id
                title
                description
            }
            modal_form {
                title
                description
                region
                portal_id
                form_id
            }
        }
    }
`
